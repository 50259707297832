var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "draggable",
    {
      attrs: {
        disabled: _vm.disabled,
        handle: _vm.handleClassName,
        animation: "300",
        ghostClass: "ghost",
        forceFallback: "true",
      },
      on: { update: _vm.onUpdate },
      model: {
        value: _vm.currentItems,
        callback: function ($$v) {
          _vm.currentItems = $$v
        },
        expression: "currentItems",
      },
    },
    [
      _c(
        "transition-group",
        { attrs: { type: "transition" } },
        _vm._l(_vm.currentItems, function (item) {
          return _c(
            "div",
            { key: item[_vm.keyAttr], class: { "no-select": _vm.extra } },
            [_vm._t("default", null, { item: item, extra: _vm.extra })],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }